import useDiscountsInfo from "./useDiscountsInfo";
import DISCOUNT_TYPES from "../constants/DiscountTypes";

const DEFAULT_COLORS = {
  hpBgColorDesktop: "#E4E1DB",
  hpBgColorMobile: "#E4E1DB",
  hpButtonBgColorDesktop: "#000000",
  hpButtonBgColorMobile: "#000000",
  hpButtonHoverBgColorDesktop: "#FFFFFF",
  hpButtonHoverBgColorMobile: "#FFFFFF",
  hpTextColorDesktop: "#FFFFFF",
  hpTextColorMobile: "#FFFFFF",
};

function useSalesColors() {
  const discountsInfo = useDiscountsInfo();

  try {
    return (
      discountsInfo.find(({ type }) => type === DISCOUNT_TYPES.QUANTITY_PERCENT).colorInformation ||
      DEFAULT_COLORS
    );
  } catch (error) {
    return DEFAULT_COLORS;
  }
}

export default useSalesColors;
