import PropTypes from "prop-types";
import { useContext } from "react";
import { formatISO, parse } from "date-fns";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { useCurrentFormatCurrency } from "../../hooks/usePrices";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { roundNumber } from "../../context/helpers";
import { ShippingDelayContext } from "../../context/ShippingDelayProvider";
import { getShipByDate, getDaysUntilShipping } from "../../helpers/product";
import useDiscountsInfo from "../../hooks/useDiscountsInfo";

import DISCOUNT_TYPES from "../../constants/DiscountTypes";
import LuxeIconCart from "../icons/svgs/luxe-cart.svg";
import { Country } from "../../constants/Country";
import { HandleType } from "../../constants/HandleType";

function PriceSummary({ sideCartInfo, shippingInfo, countryCode, isLuxe }) {
  const discountsInfo = useDiscountsInfo();
  const { deliveryDateFormatted, isShippingEstimationLoading } = useContext(ShippingDelayContext);
  const formatCurrency = useCurrentFormatCurrency();
  const dict = useLocalizedSentenceDict();

  const siteWideDiscountItemCount = sideCartInfo.nonBannedItemQuantity;
  const hasFreeShipping =
    (shippingInfo?.freeShippingThreshold &&
      sideCartInfo.finalAmount > shippingInfo.freeShippingThreshold) ||
    shippingInfo?.freeShippingThreshold === 0;

  const preOrderItems = sideCartInfo.lines.filter((lineItem) =>
    lineItem.attributes?.find(({ key, value }) => key === "_pre_order" && value === "true")
  );

  let deliveryDateToDisplay = `Estimated Delivery by: ${deliveryDateFormatted}`;

  // Show preorder date only if there is one item in the cart and that item is a preorder
  if (
    sideCartInfo.lines.filter(
      (l) =>
        ![HandleType.SUBSCRIPTION, HandleType.ORDER_PROTECTION].includes(
          l?.variant?.product?.handle
        )
    ).length === 1 &&
    preOrderItems.length === 1
  ) {
    const shipsByDateAttr =
      preOrderItems[0].attributes?.find(({ key }) => key === "_ships_by_pre_order")?.value || "";
    const shipsByDate = parse(shipsByDateAttr, "MMM d, yyyy", new Date());
    const daysUntilShipping = shipsByDate ? getDaysUntilShipping(shipsByDate) : 0;
    if (daysUntilShipping > 7) {
      deliveryDateToDisplay = `Ships by: ${getShipByDate(
        formatISO(shipsByDate, { representation: "date" }),
        "MMM do"
      )}`;
    }
  }
  if (preOrderItems.length > 1) {
    deliveryDateToDisplay = "";
  }

  const tierDiscount = discountsInfo.find(({ type }) => type === DISCOUNT_TYPES.QUANTITY_PERCENT);
  const showStandardDiscountMessage =
    !tierDiscount ||
    (!!tierDiscount && siteWideDiscountItemCount < tierDiscount.thresholdConditionQuantity[0]);

  let discountCodeText = "";

  if (!!tierDiscount && tierDiscount.thresholdConditionQuantity.length > 0) {
    const firstTierQuantityThreshold = tierDiscount.thresholdConditionQuantity[0];
    const lastTierQuantityThreshold =
      tierDiscount.thresholdConditionQuantity[tierDiscount.thresholdConditionQuantity.length - 1];
    const lastTierDiscountPercentage =
      tierDiscount.thresholdResultPercentage[tierDiscount.thresholdResultPercentage.length - 1];

    if (
      siteWideDiscountItemCount < lastTierQuantityThreshold &&
      siteWideDiscountItemCount >= firstTierQuantityThreshold
    ) {
      let appliedDiscountIndex = 0;
      for (let i = 0; i < tierDiscount.thresholdConditionQuantity.length; i += 1) {
        if (siteWideDiscountItemCount >= tierDiscount.thresholdConditionQuantity[i]) {
          appliedDiscountIndex = i;
        }
      }
      discountCodeText = `${tierDiscount.thresholdResultPercentage[appliedDiscountIndex]}% OFF`;
    }
    if (siteWideDiscountItemCount >= lastTierQuantityThreshold) {
      discountCodeText = `${lastTierDiscountPercentage}% OFF`;
    }
  }

  const shippingValueUS = (
    <span>
      <span className="sidecart_shipping_price">{formatCurrency(5.99)}</span>
      <span className="sidecart_shipping_free">FREE</span>
    </span>
  );

  let shippingValue = "";
  if (hasFreeShipping) {
    shippingValue = dict.get("FREE");
  } else if (countryCode === Country.US) {
    shippingValue = shippingValueUS;
  } else {
    shippingValue = formatCurrency(shippingInfo?.shippingPrice);
  }

  const diamondDiscountGuest = sideCartInfo.lines
    .filter((item) =>
      ["Components:10K Solid Gold", "Components:10K Solid White Gold", "Components:Diamond"].some(
        (tag) => item.variant.product.tags.includes(tag)
      )
    )
    .reduce((red, item) => (red += item.variant.discount_percent_off), 0);

  return (
    <div className="sidecart_total">
      {/* Subtotal */}
      <p className="sidecart_total_content">
        <span>{dict.get("Subtotal")}</span>
        <span>{formatCurrency(sideCartInfo.fullAmount)}</span>
      </p>

      {/* AL Luxe */}
      {isLuxe && sideCartInfo.discountLuxe > 0 && (
        <p className="sidecart_total_content">
          <span className="sidecart_total_content--color mr--5">
            <LuxeIconCart style={{ marginRight: "3px" }} />
            <b>Extra 5% OFF</b>
          </span>
          <span className="sidecart_total_content_value_color">
            {`-${formatCurrency(roundNumber(sideCartInfo.discountLuxe))}`}
          </span>
        </p>
      )}

      {/* Buy More, Save More */}
      {sideCartInfo.fullAmount !== sideCartInfo.finalAmount &&
        sideCartInfo.discountTieredOffer > 0 && (
          <p className="sidecart_total_content" data-testid="sidecart_total_content_discount">
            <span>
              <span className="sidecart_total_content--color mr--5">
                {showStandardDiscountMessage
                  ? dict.get("Discount")
                  : dict.get("Buy More, Save More:")}
              </span>
              <span className="sidecart_total_content_title_discount">{discountCodeText}</span>
            </span>

            <span className="sidecart_total_content_value_color">
              {`-${formatCurrency(roundNumber(sideCartInfo.discountTieredOffer))}`}
            </span>
          </p>
        )}

      {/* Diamond Days Discount */}
      {diamondDiscountGuest > 0 && (
        <p className="sidecart_total_content">
          <span>
            <span className="sidecart_total_content--color mr--5">
              {dict.get(
                `${
                  discountsInfo.find((d) => d.type === "percent_off")?.value
                }% Off Diamonds and Solid Gold`
              )}
            </span>
          </span>

          <span className="sidecart_total_content_value_color">
            {`-${formatCurrency(roundNumber(diamondDiscountGuest))}`}
          </span>
        </p>
      )}

      {/* Last Chance Discount */}
      {sideCartInfo.discountCompareAtPrice > 0 && (
        <p className="sidecart_total_content">
          <span>
            <span className="sidecart_total_content--color mr--5">
              {dict.get("Last Chance Discount:")}
            </span>
          </span>

          <span className="sidecart_total_content_value_color">
            {`-${formatCurrency(roundNumber(sideCartInfo.discountCompareAtPrice))}`}
          </span>
        </p>
      )}

      <div className="sidecart_total_content">
        <div className="sidecart_total_content_shipping">
          <p className="sidecart_total_content_shipping_title">{dict.get("Shipping")}</p>
          {deliveryDateToDisplay && !isShippingEstimationLoading && (
            <p className="sidecart_total_content_shipping_dates microtext">
              {deliveryDateToDisplay}
            </p>
          )}
        </div>
        <span className={`${hasFreeShipping ? "sidecart_total_content_value_color" : ""}`}>
          {shippingValue}
        </span>
      </div>
    </div>
  );
}

PriceSummary.propTypes = {
  sideCartInfo: PropTypes.shape({
    lines: PropTypes.array,
    fullAmount: PropTypes.number,
    finalAmount: PropTypes.number,
    nonBannedItemQuantity: PropTypes.number,
    discountLuxe: PropTypes.number,
    discountTieredOffer: PropTypes.number,
    discountCompareAtPrice: PropTypes.number,
  }).isRequired,
  shippingInfo: PropTypes.shape({
    freeShippingThreshold: PropTypes.number,
    shippingPrice: PropTypes.number,
  }).isRequired,
  countryCode: PropTypes.string.isRequired,
  isLuxe: PropTypes.bool,
};

export default withALErrorBoundary({
  name: "PriceSummary",
  priority: "P1",
})(PriceSummary);
