import { useCallback, useContext } from "react";
import { Link } from "gatsby";
import { PropTypes } from "prop-types";

import { GeoContext } from "../../../context/GeoProvider";
import { withALErrorBoundary } from "../../../helpers/ErrorBoundary/ALErrorBoundary";
import { resizeShopifyImage } from "../../../context/helpers";
import { ALButton } from "../../ALComponents";

import { useFillProductPrices, usePriceInfo } from "../../../hooks/usePrices";
import { useLocalizedSentenceDict } from "../../../hooks/useSentenceDict";
import { useTracking } from "../../../context/Tracking";
import { useAddToCartWithLuxe } from "../../../context/LuxeProvider";

import PriceContainer from "../../PriceContainer";
import { ComponentType } from "../../../constants/ComponentType";
import { Country } from "../../../constants/Country";

import "./upSellItem/styles.scss";
import useDiscountsInfo from "../../../hooks/useDiscountsInfo";

function UpSellItem({
  product: originalProduct,
  recommendationId,
  atcButtonDisabled = false,
  atcButtonText = "ADD",
}) {
  const { trackRecommendedProduct } = useTracking();
  const addToCartWithLuxe = useAddToCartWithLuxe();
  const discountsInfo = useDiscountsInfo();

  const { gePriceDetails } = useContext(GeoContext);
  const countryCode = gePriceDetails?.CountryCode;

  const product = useFillProductPrices({ product: originalProduct, shouldUseDiscount: true });
  const dict = useLocalizedSentenceDict();

  const isFinalSale = (product?.tags || []).includes("Final Sale");

  const [{ data: prices, isInitialLoading: pricesLoading }] = usePriceInfo({
    handles: [product.handle],
    tags: { [product.handle]: product?.tags },
  });

  const handleAddToCart = useCallback(
    async (product) => {
      trackRecommendedProduct("add", "Side Cart", {
        productTitle: product?.title,
        productCategory: product?.category,
        productId: product?.id,
        recommendationId,
      });

      const variant = product.variants[0];
      const dataProductToAdd = {
        quantity: 1,
        attributes: [],
        variant: {
          id: variant.id,
          sku: variant.sku,
          price: variant.price,
          compareAtPrice: variant.compareAtPrice,
          product: {
            title: product.title,
            id: product.id,
            handle: product.handle,
            productType: product.productType,
            images: product.images,
          },
        },
      };

      dataProductToAdd.attributesProperties = {
        isFinalSale: product.tags?.includes("Final Sale"),
        isUS: countryCode === Country.US,
        currencyCode: prices.currencyCode,
        prices,
        discountsInfo,
      };
      await addToCartWithLuxe({ dataProductsToAdd: [dataProductToAdd] });
    },
    [
      addToCartWithLuxe,
      countryCode,
      discountsInfo,
      prices,
      recommendationId,
      trackRecommendedProduct,
    ]
  );

  return (
    <div className="upsell_item" key={product.handle}>
      <Link
        className="upsell_item__link"
        to={`/products/${product.handle}?origin_content=recommended-upsell_sidecart`}
      >
        <img
          src={resizeShopifyImage(product.images[0].url, "x200")}
          alt={product.images[0].altText}
        />
      </Link>
      <div className="upsell_item__content">
        <div className="upsell_item__content_title_price">
          <p className="subtext subtext--uc subtext--bold upsell_item__content_title">
            {product.title.split(" - ")[1] || product.title}
          </p>
          <PriceContainer
            productId={product.id}
            tags={product.tags}
            selectedVariant={product.variants[0]}
            componentType={ComponentType.SIDE_CART_UPSELL}
            prices={prices}
            pricesLoading={pricesLoading}
          />
        </div>

        {isFinalSale && (
          <p className="upsell_item__content_subtitle subtext">{dict.get("Final Sale")}</p>
        )}

        <ALButton
          variant="secondary"
          size="xsmall"
          fullWidth
          className="upsell_item__content_cta"
          onClick={() => handleAddToCart(product)}
          disabled={atcButtonDisabled}
        >
          {dict.get(atcButtonText)}
        </ALButton>
      </div>
    </div>
  );
}

UpSellItem.propTypes = {
  product: PropTypes.shape({
    handle: PropTypes.string,
    title: PropTypes.string,
    recommendationId: PropTypes.string,
    variants: PropTypes.array,
    images: PropTypes.array,
  }),
  recommendationId: PropTypes.string,
  atcButtonDisabled: PropTypes.bool,
  atcButtonText: PropTypes.string,
};

export default withALErrorBoundary({
  name: "UpSellItem",
  priority: "P2",
})(UpSellItem);
