import { useContext, useMemo } from "react";
import { DiscountContext } from "../context/DiscountProvider";

const DEFAULT_EXCLUSION_TAGS = ["Final Sale", "EXCLUSION"];

export const EXCLUSION_TAGS_MODES = {
  ALL: "all",
  LUXE_ONLY: "luxe_only",
  NON_LUXE_ONLY: "non_luxe_only",
};

export function useExclusionTags(mode = EXCLUSION_TAGS_MODES.NON_LUXE_ONLY) {
  const { localizedDiscount } = useContext(DiscountContext);
  return useMemo(() => {
    let result = [];
    switch (mode) {
      case EXCLUSION_TAGS_MODES.ALL:
        result = localizedDiscount.reduce(
          (acc, { matchType, matchTags }) => [
            ...acc,
            ...((matchType === "exclude" && matchTags) || []),
          ],
          []
        );
        break;
      case EXCLUSION_TAGS_MODES.LUXE_ONLY:
        result = localizedDiscount.reduce(
          (acc, { isLuxe, matchType, matchTags }) => [
            ...acc,
            ...(isLuxe && matchType === "exclude" && matchTags ? matchTags : []),
          ],
          []
        );
        break;
      case EXCLUSION_TAGS_MODES.NON_LUXE_ONLY:
        result = localizedDiscount.reduce(
          (acc, { isLuxe, matchType, matchTags }) => [
            ...acc,
            ...(!isLuxe && matchType === "exclude" && matchTags ? matchTags : []),
          ],
          []
        );
        break;
      default:
        return DEFAULT_EXCLUSION_TAGS;
    }
    return result.length ? result : DEFAULT_EXCLUSION_TAGS;
  }, [localizedDiscount, mode]);
}
