import { useContext, useMemo } from "react";
import { DiscountContext } from "../context/DiscountProvider";
import { LuxeContext } from "../context/LuxeProvider";
import { useCurrentCountryCode } from "./usePrices";
import { Country } from "../constants/Country";

export const DISCOUNT_INFO_MODES = {
  ALL: "all",
  LUXE_ONLY: "luxe_only",
  NON_LUXE_ONLY: "non_luxe_only",
  CURRENT: "current", // Current Luxe status (active, in cart, etc.)
};

// Filter Localized Discount Information with Luxe information
export default function useDiscountsInfo(mode = DISCOUNT_INFO_MODES.CURRENT) {
  const { discountInfo } = useContext(DiscountContext);
  const { luxe } = useContext(LuxeContext);
  const countryCode = useCurrentCountryCode();

  const shouldShowLuxeExperience =
    countryCode === Country.US &&
    (luxe.isLuxeActive || luxe.hasLuxeInCart || luxe.shouldAddLuxeInNextATC);

  return useMemo(() => {
    if (!discountInfo) {
      return [];
    }
    if (mode === DISCOUNT_INFO_MODES.ALL) {
      return discountInfo;
    }
    if (mode === DISCOUNT_INFO_MODES.LUXE_ONLY) {
      return discountInfo.filter((discount) => discount.isLuxe);
    }
    if (mode === DISCOUNT_INFO_MODES.NON_LUXE_ONLY) {
      return discountInfo.filter((discount) => !discount.isLuxe);
    }
    // Default to CURRENT
    return discountInfo.filter((discount) => discount.isLuxe === shouldShowLuxeExperience);
  }, [discountInfo, shouldShowLuxeExperience, mode]);
}
