import { useStaticQuery, graphql } from "gatsby";

import { useCurrentLang } from "./useLocalizedCartInfo";
import { useCurrentCountryCode } from "./usePrices";
import useDiscountsInfo from "./useDiscountsInfo";
import { getDiscountText } from "../components/cartDiscounts";
import { edgesToArray } from "../context/helpers";
import DISCOUNT_TYPES from "../constants/DiscountTypes";

// eslint-disable-next-line import/prefer-default-export
export function useAnnouncementBar() {
  const lang = useCurrentLang();
  const countryCode = useCurrentCountryCode();
  const discountsInfo = useDiscountsInfo();

  const data = useStaticQuery(graphql`
    query {
      allContentfulAnnouncementBarContent {
        edges {
          node {
            node_locale
            name
            countries {
              code
              country
            }
            announcementTitle
            announcementSubtitle
            disclaimer
            isSticky
            isRotating
            hasTimer
            dateTimerStart
            dateTimerEnd
            timerCountdown
            hasShipping
            freeShipping
            hasPopup
            backgroundColor
            textColor
            popup {
              title
              subtitle
              deadlines {
                internal {
                  content
                }
              }
              backgroundImage {
                title
                url
              }
              textColor
            }
          }
        }
      }
    }
  `);

  if (!discountsInfo || discountsInfo.length === 0) {
    return {
      announcementTitleElement: "",
    };
  }

  let dataAB = null;
  let influencerData = null;
  let bogoContentData = null;
  let fallbackData = null;

  if (data && "allContentfulAnnouncementBarContent" in data) {
    const allAnnouncementBar = edgesToArray(data.allContentfulAnnouncementBarContent);

    // FIND INFLUENCER
    influencerData = allAnnouncementBar.find(
      (n) => n.node_locale === lang && n?.name === "INFLUENCER"
    );
    // FIND BOGO (from utms)
    bogoContentData = allAnnouncementBar.find(
      (n) => n.node_locale === lang && n?.name === "UTM_BOGO"
    );

    // FIND FALLBACKS
    fallbackData = allAnnouncementBar.find((n) => n.node_locale === lang && n?.name === "FALLBACK");

    // RoW + No influencers & fallback
    const defaultAnnouncementBar = allAnnouncementBar
      .filter((n) => n.name !== "FALLBACK" && n.name !== "INFLUENCER" && n.name !== "UTM_BOGO")
      .find((n) => n?.countries?.some((c) => c.code === "RoW") && n.node_locale === lang);

    const localAnnouncementBar = allAnnouncementBar.find(
      (n) => n?.countries?.some((c) => c.code === countryCode) && n.node_locale === lang
    );

    dataAB = localAnnouncementBar ?? defaultAnnouncementBar;
  }

  let contentAB = null;
  if (dataAB) {
    if (!discountsInfo?.[0]?.isMaster && discountsInfo?.[0]?.isFromParams) {
      if (discountsInfo?.[0]?.type === DISCOUNT_TYPES.BOGO && bogoContentData) {
        contentAB = bogoContentData;
      } else {
        // Default to % discount
        contentAB = influencerData;
      }
    } else {
      // CURRENT dataAB
      contentAB = dataAB;
    }
  } else {
    // FALLBACK
    contentAB = fallbackData;
  }

  if (!contentAB) {
    contentAB = {
      announcementTitle: "",
    };
  }

  const {
    announcementTitle,
    announcementSubtitle,
    disclaimer,
    freeShipping,
    isSticky,
    isRotating,
    hasTimer,
    dateTimerStart,
    dateTimerEnd,
    timerCountdown,
    hasPopup,
    hasShipping,
    backgroundColor,
    textColor,
    popup,
  } = contentAB;

  return {
    announcementTitleElement: announcementTitle,
    announcementSubtitle,
    disclaimer,
    isSticky,
    isRotating,
    hasTimer,
    dateTimerStart,
    dateTimerEnd,
    timerCountdown,
    hasShipping,
    hasPopup,
    freeShipping,
    discountCode: discountsInfo?.[0]?.code,
    discountText: getDiscountText(discountsInfo?.[0]),
    backgroundColor,
    textColor,
    popup,
  };
}
