import { useContext, useMemo } from "react";

import { GeoContext } from "../context/GeoProvider";
import { CartContext } from "../context/CartProvider";
import { getCookie, getLocalizedPricesWithDiscount } from "../context/helpers";
import { useALError, alReportError } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { useExclusionTags } from "./useExclusionTags";
import { applyCartDiscounts } from "../components/cartDiscounts";
import useDiscountsInfo from "./useDiscountsInfo";

export function getGlobalEData() {
  const geData = getCookie("GlobalE_Data");
  const defaultData = {
    countryCode: "US",
    countryISO: "US",
    countryName: "United States",
    currencyCode: "USD",
    cultureCode: "en-US",
  };
  try {
    const result = geData ? JSON.parse(decodeURIComponent(geData)) : defaultData;
    return result;
  } catch (e) {
    console.error(e);
    console.error("raw cookie", geData);
    alReportError(e);
    return defaultData;
  }
}

export function useCurrentLang() {
  const { gePriceDetails } = useContext(GeoContext);

  return gePriceDetails?.Culture ?? "en-US";
}

export function useLocalizedCartInfo(inUSD = false) {
  const { sendReport } = useALError();
  const { cart } = useContext(CartContext);
  const { gePriceDetails: storeGEPriceDetails } = useContext(GeoContext);

  const gePriceDetails = useMemo(
    () =>
      inUSD
        ? {
            CountryCode: "US",
            CountryName: "United States",
            CurrencyCode: "USD",
            Culture: "en-US",
            CurrencySymbol: "$",
          }
        : storeGEPriceDetails,
    [storeGEPriceDetails, inUSD]
  );
  const { lines } = cart;
  const discountsInfo = useDiscountsInfo();
  const sideCartInfo = useMemo(() => {
    try {
      return applyCartDiscounts(lines, discountsInfo);
    } catch (error) {
      sendReport(error, { name: "useLocalizedCartInfo", priority: "P1" });
      throw error;
    }
  }, [lines, discountsInfo, gePriceDetails, sendReport]);
  return sideCartInfo;
}

export function useLocalizedProductsWithDiscount(products, inUSD = false) {
  const { gePriceDetails: storeGEPriceDetails } = useContext(GeoContext);
  const discountsInfo = useDiscountsInfo();
  const exclusionTags = useExclusionTags();

  const gePriceDetails = inUSD
    ? {
        CountryCode: "US",
        CountryName: "United States",
        CurrencyCode: "USD",
        Culture: "en-US",
        CurrencySymbol: "$",
      }
    : storeGEPriceDetails;
  return products?.map((product) => {
    const isBannedProduct = (product.tags || []).some((r) => exclusionTags.includes(r));
    const variants = product.variants.map((v) => {
      const prices = getLocalizedPricesWithDiscount({
        productHandle: product.handle,
        compareAtPrice: v.compareAtPrice,
        price: v.price,
        discounts: discountsInfo,
        geDetails: gePriceDetails,
        shouldUseDiscount: !isBannedProduct,
      });
      return {
        ...v,
        fullPrice: prices.full_price,
        price: prices.final_price,
        currencyCode: gePriceDetails?.CurrencyCode,
        currencySymbol: gePriceDetails?.CurrencySymbol ?? "$",
      };
    });
    return { ...product, variants };
  });
}
