import PropTypes from "prop-types";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";

import useUpSellProducts from "../../hooks/useUpSellProducts";
import { useLocalizedProductsWithDiscount } from "../../hooks/useLocalizedCartInfo";

import UpSells from "./UpSells";

function UpSellsRecommendation({ lines = [] }) {
  const { data: recommendedProducts, isLoading, id: recommendationId } = useUpSellProducts(lines);
  const products = useLocalizedProductsWithDiscount(recommendedProducts);

  return (
    <UpSells
      title="You may also like"
      products={products}
      isLoading={isLoading}
      recommendationId={recommendationId}
      slidesPerView={1.3}
    />
  );
}

UpSellsRecommendation.propTypes = {
  lines: PropTypes.array,
};

export default withALErrorBoundary({
  name: "UpSellsRecommendation",
  priority: "P3",
})(UpSellsRecommendation);
