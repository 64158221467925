// import { produce } from "immer";
// import {
//   getLocalizedPricesWithDiscount,
//   fRound,
//   getItemCipheredAttributeNumberValue,
// } from "../context/helpers";
// import {
//   BYOB_ATTR_KEY,
//   BYOB_DEFAULT_PERCENTAGE_OFF,
//   BYOB_PERCENTAGE_OFF_KEY,
// } from "../constants/BYOBConfig";
import DISCOUNT_TYPES from "../constants/DiscountTypes";
import { HandleType } from "../constants/HandleType";

// function validateDiscountInfo(discountInfo) {
//   switch (discountInfo.type) {
//     case DISCOUNT_TYPES.SITEWIDE_PERCENT:
//     case DISCOUNT_TYPES.BOGO:
//       if (
//         Number.isInteger(discountInfo.value) &&
//         discountInfo.value > 0 &&
//         discountInfo.value < 100
//       ) {
//       } else {
//         throw new Error(
//           `discountInfo.value must have an integer between 0 and 100, got: ${discountInfo.value}`
//         );
//       }
//       break;
//     case DISCOUNT_TYPES.QUANTITY_PERCENT:
//     case DISCOUNT_TYPES.AMOUNT_PERCENT:
//     case DISCOUNT_TYPES.AMOUNT_VALUE:
//       if (!(Array.isArray(discountInfo.discounts) && Array.isArray(discountInfo.discounts[0]))) {
//         throw new Error("discountInfo.discounts must be an array that contains an array");
//       }
//       break;
//     default:
//       break;
//   }
// }

export function getDiscountText(discountInfo) {
  switch (discountInfo?.type) {
    case DISCOUNT_TYPES.SITEWIDE_PERCENT:
    case DISCOUNT_TYPES.BOGO:
      return `${discountInfo.value}%`;
    case DISCOUNT_TYPES.QUANTITY_PERCENT:
    case DISCOUNT_TYPES.AMOUNT_PERCENT:
    case DISCOUNT_TYPES.AMOUNT_VALUE:
      const value = discountInfo.discounts[discountInfo.discounts.length - 1];
      return discountInfo.type.includes("dollar") ? `$${value[1]}` : `${value[1]}%`;
    default:
      return `${discountInfo.value}%`;
  }
}

export function findDiscountInThresholds(discountThresholds, value) {
  let idx = discountThresholds.length - 1;
  for (let i = 0; i < discountThresholds.length; i++) {
    const [threshold] = discountThresholds[i];
    if (threshold > value) {
      idx = i - 1;
      break;
    }
  }
  const discountValue = idx >= 0 ? discountThresholds[idx][1] : 0;
  return discountValue;
}

// function computeDiscountPercent(fullPrice, finalPrice) {
//   return Math.round((100 * (fullPrice - finalPrice)) / fullPrice);
// }

/*
  Apply BYOB sale and transform lineitems into
  [
    {
      items: [lineItem, lineItem, lineItem] <- BYOB
      quantity
      full_price
      final_price
    },
    {
      items: [lineItem] <- classic
      quantity
      full_price
      final_price
    }
  ]
*/
// function getBYOBUnifiedLineItems(lineItems) {
//   return Object.entries(
//     lineItems.reduce((acc, item) => {
//       const bundleAttribute = (item.attributes || []).find(({ key }) => key === BYOB_ATTR_KEY);
//       if (bundleAttribute) {
//         return {
//           ...acc,
//           [bundleAttribute.value]: [...(acc[bundleAttribute.value] || []), item],
//         };
//       }
//       return { ...acc, none: [...(acc.none || []), item] };
//     }, {})
//   ).reduce((acc, [key, items]) => {
//     if (key === "none") {
//       return [
//         ...acc,
//         ...items.map((item) => ({
//           quantity: item.quantity,
//           full_price: acc.full_price,
//           final_price: item.final_price,
//           items: [item],
//         })),
//       ];
//     }
//     // Apply BYOB sale
//     items.forEach((item) => {
//       const percentageOffBYOB = getItemCipheredAttributeNumberValue(
//         item,
//         BYOB_PERCENTAGE_OFF_KEY,
//         BYOB_DEFAULT_PERCENTAGE_OFF
//       );
//       item.variant.price_before_byob_discount = item.variant.final_price;
//       item.variant.final_price = (item.variant.final_price * (100 - percentageOffBYOB)) / 100;
//       item.final_price = item.variant.final_price;
//     });
//     return [
//       ...acc,
//       {
//         ...items.reduce(
//           (acc, item) => ({
//             quantity: 1, // BYOB is like a single product in BOGO
//             final_price: fRound(acc.final_price + item.variant.final_price),
//             price_before_byob_discount: fRound(
//               acc.price_before_byob_discount + item.variant.price_before_byob_discount
//             ),
//             full_price: fRound(acc.full_price + item.variant.full_price),
//           }),
//           { full_price: 0, price_before_byob_discount: 0, final_price: 0, quantity: 0 }
//         ),
//         items,
//         isBYOB: true,
//       },
//     ];
//   }, []);
// }

/**
 *
 * @returns create an immutable copy of lineItems to avoid interference with the original lineItems stored in context
 */
// function applyDiscountToLineItems(lineItems, discountInfo, shouldApplySiteWideDiscount) {
//   validateDiscountInfo(discountInfo);

//   return produce(lineItems, (draftItems) => {
//     // Initial conversion from the format used externally
//     draftItems.forEach((di) => {
//       const isByoLineItem = (di.attributes || []).some(({ key }) => key === BYOB_ATTR_KEY);

//       // Quantity percent discount is already applied in the backend
//       // (full_price and final_price are already computed)
//       const shouldRecomputePrices =
//         discountInfo.type !== DISCOUNT_TYPES.QUANTITY_PERCENT ||
//         (discountInfo.type === DISCOUNT_TYPES.QUANTITY_PERCENT && isByoLineItem);

//       let prices = {};
//       if (shouldRecomputePrices) {
//         prices = getLocalizedPricesWithDiscount({
//           compareAtPrice: di.variant.compareAtPrice?.amount,
//           price: di.variant.price?.amount,
//           discount: discountInfo,
//           geDetails: { CountryCode: "US" }, // Use US geDetails for now to prevent currency conversion since localized prices are already fetched from Shopify.
//           shouldUseDiscount:
//             !isByoLineItem && // BYO products are excluded from site-wide discounts
//             !(di.variant.product.tags || []).some(
//               (r) =>
//                 (discountInfo.matchType === "exclude" && discountInfo.matchTags.includes(r)) ||
//                 (discountInfo.matchType === "include" && !discountInfo.matchTags.includes(r))
//             ),
//         });
//       } else {
//         prices = {
//           full_price: Number.parseFloat(di.variant.full_price),
//           final_price: Number.parseFloat(di.variant.final_price),
//           price_before_sitewide_discount: Number.parseFloat(di.variant.price?.amount), // used for legacy use cases
//           currency: "$",
//         };
//       }

//       if (shouldRecomputePrices) {
//         di.variant.full_price = prices.full_price * di.quantity;
//         di.variant.final_price = prices.final_price * di.quantity;
//       }
//       di.full_price = di.variant.full_price;
//       di.final_price = di.variant.final_price;
//       di.price_before_sitewide_discount = prices.price_before_sitewide_discount * di.quantity;
//       di.variant.currency = prices.currency;
//     });

//     // No discount if total cart amount < 25 USD
//     if (!shouldApplySiteWideDiscount) {
//       draftItems.forEach((di) => {
//         di.variant.final_price = di.price_before_sitewide_discount;
//       });
//       return;
//     }

//     // filter out banned products if not part of BYO
//     let eligibleItems = draftItems.filter(
//       (itm) =>
//         !(itm?.attributes || []).some(({ key }) => key === BYOB_ATTR_KEY) &&
//         !(itm.variant.product.tags || []).some(
//           (r) =>
//             (discountInfo.matchType === "exclude" && discountInfo.matchTags.includes(r)) ||
//             (discountInfo.matchType === "include" && !discountInfo.matchTags.includes(r))
//         )
//     );

//     let discountType = discountInfo.type;
//     if (eligibleItems.length === 1 && eligibleItems[0].quantity === 1) {
//       discountType = DISCOUNT_TYPES.SITEWIDE_PERCENT;
//     }

//     // calculate the discount value
//     switch (discountType) {
//       // Do nothing for sitewide percent - already calculated in getBasePrices
//       // Do nothing for quantity percent - applied in sales functions
//       case DISCOUNT_TYPES.SITEWIDE_PERCENT:
//       case DISCOUNT_TYPES.QUANTITY_PERCENT:
//         break;
//       case DISCOUNT_TYPES.BOGO: {
//         // /!\ Discount logic is based on **tags**, hence we start with draftItems and not eligibleItems
//         // BOGO BYOB
//         eligibleItems = getBYOBUnifiedLineItems(draftItems);

//         // Exclusion logic:
//         // - BYOB: if **all** with exclusion tag
//         // - Single product: if with exclusion tag
//         const exclusionTags =
//           (discountInfo.matchType === "exclude" && discountInfo.matchTags) || [];
//         const inclusionTags =
//           (discountInfo.matchType === "include" && discountInfo.matchTags) || [];
//         eligibleItems = eligibleItems.filter(({ items }) =>
//           items.some(
//             (item) =>
//               !(item.variant?.product?.tags || []).some(
//                 (tag) => exclusionTags.includes(tag) || !inclusionTags.includes(tag)
//               )
//           )
//         );

//         eligibleItems.sort((i1, i2) => {
//           const i1Price = i1.isBYOB ? i1.price_before_byob_discount : i1.final_price;
//           const i2Price = i2.isBYOB ? i2.price_before_byob_discount : i2.final_price;
//           const u1 = i1Price / i1.quantity;
//           const u2 = i2Price / i2.quantity;
//           return u1 - u2;
//         });

//         const totalQuantity = eligibleItems.reduce((acc, itm) => acc + itm.quantity, 0);
//         const itemCountToDiscount = Math.floor(totalQuantity / 2);
//         let discounted = 0;
//         for (const item of eligibleItems) {
//           if (discounted >= itemCountToDiscount) break;
//           const itemQtyToDiscount =
//             itemCountToDiscount - discounted > item.quantity
//               ? item.quantity
//               : itemCountToDiscount - discounted;

//           if (item.isBYOB) {
//             // Bundles (BYOB)
//             item.items.forEach((item) => {
//               item.bogoApplied = true;
//               item.variant.final_price = fRound(
//                 (item.variant.final_price * (100 - discountInfo.value)) / 100
//               );
//             });
//           } else {
//             // Classic
//             const unitFinalPrice = item.items[0].variant.final_price / item.items[0].quantity;
//             const finalPriceUndiscounted =
//               (item.items[0].quantity - itemQtyToDiscount) * unitFinalPrice;
//             const finalPriceDiscounted =
//               itemQtyToDiscount * ((unitFinalPrice * (100 - discountInfo.value)) / 100);
//             item.items[0].bogoApplied = true;
//             item.items[0].variant.final_price = fRound(
//               finalPriceDiscounted + finalPriceUndiscounted
//             );
//           }
//           discounted += itemQtyToDiscount;
//         }
//         break;
//       }
//       case DISCOUNT_TYPES.AMOUNT_PERCENT: {
//         const totalAmount = eligibleItems.reduce((c, itm) => c + itm.variant.full_price, 0);
//         const percent2 = findDiscountInThresholds(discountInfo.discounts, totalAmount);
//         for (const item of eligibleItems) {
//           item.variant.final_price = (item.variant.final_price * (100 - percent2)) / 100;
//         }
//         break;
//       }
//       case DISCOUNT_TYPES.AMOUNT_VALUE:
//         // Do nothing here. We'll apply the discount to total amount in applyCartDiscounts function
//         break;
//       default:
//         break;
//     }

//     if (discountType !== DISCOUNT_TYPES.BOGO) {
//       // Apply BYOB discount override
//       draftItems.forEach((di) => {
//         if (di?.attributes?.some(({ key }) => key === BYOB_ATTR_KEY)) {
//           const percentageOffBYOB = getItemCipheredAttributeNumberValue(
//             di,
//             BYOB_PERCENTAGE_OFF_KEY,
//             BYOB_DEFAULT_PERCENTAGE_OFF
//           );

//           di.variant.final_price = fRound(
//             di.variant.final_price * ((100 - percentageOffBYOB) / 100)
//           );
//         }
//       });
//     }

//     // discount_given
//     draftItems.forEach((di) => {
//       di.variant.discount_given = computeDiscountPercent(
//         di.variant.full_price,
//         di.variant.final_price
//       );
//     });
//   });
// }

/**
 *
 * @returns an object with the following structure:
 * {
 *     lineItems: [...],
 *     fullAmount: 120,  // the full amount of the order
 *     finalAmount: 100, // the final amount of the order (after applying all the discounts)
 * }
 *
 * */
export function applyCartDiscounts(lineItems, discountsInfo) {
  if (!discountsInfo || !discountsInfo.length || !lineItems)
    return { lines: [], fullAmount: 0, finalAmount: 0 };

  // const siteWideThresholdInUSD = discountInfo?.minimumPurchaseAmount ?? 25;
  // const amountInUSD = lineItems.reduce(
  //   (acc, item) => acc + item.variant.price.amount * item.quantity,
  //   0
  // );

  // const shouldApplySiteWideDiscount = amountInUSD >= siteWideThresholdInUSD;
  let fullAmount = 0;
  let finalAmount = 0;
  let finalAmountLux = 0;
  let discountCompareAtPrice = 0; // Compare At Price
  let discountLuxe = 0;
  let discountTieredOffer = 0;
  let discountTieredOfferLuxe = 0;
  let discountPercentOff = 0;
  let discountPercentOffLuxe = 0;
  let discountFull = 0; // Should we add that? Or keep fullAmount - finalAmount
  const EXCLUDED_FROM_TOTAL = [
    HandleType.ORDER_PROTECTION, // Navidium
    HandleType.SUBSCRIPTION, // Subscription
  ];

  const discountedLineItems = lineItems;

  fullAmount += discountedLineItems.reduce((c, itm) => {
    if (!EXCLUDED_FROM_TOTAL.includes(itm.variant.product.handle)) {
      return c + itm.variant.full_price;
    }
    return c;
  }, 0);
  finalAmount += discountedLineItems.reduce((c, itm) => {
    if (!EXCLUDED_FROM_TOTAL.includes(itm.variant.product.handle)) {
      return c + itm.variant.final_price;
    }
    return c;
  }, 0);
  finalAmountLux += discountedLineItems.reduce((c, itm) => {
    if (!EXCLUDED_FROM_TOTAL.includes(itm.variant.product.handle)) {
      return c + itm.variant.final_price_luxe;
    }
    return c;
  }, 0);
  discountCompareAtPrice += discountedLineItems.reduce((c, itm) => {
    if (!EXCLUDED_FROM_TOTAL.includes(itm.variant.product.handle)) {
      return c + itm.variant.discount_compare_at_price;
    }
    return c;
  }, 0);
  discountTieredOffer += discountedLineItems.reduce((c, itm) => {
    if (!EXCLUDED_FROM_TOTAL.includes(itm.variant.product.handle)) {
      return c + itm.variant.discount_tiered_offer;
    }
    return c;
  }, 0);
  discountTieredOfferLuxe += discountedLineItems.reduce((c, itm) => {
    if (!EXCLUDED_FROM_TOTAL.includes(itm.variant.product.handle)) {
      return c + itm.variant.discount_tiered_offer_luxe;
    }
    return c;
  }, 0);
  discountPercentOff += discountedLineItems.reduce((c, itm) => {
    if (!EXCLUDED_FROM_TOTAL.includes(itm.variant.product.handle)) {
      return c + itm.variant.discount_percent_off;
    }
    return c;
  }, 0);
  discountPercentOffLuxe += discountedLineItems.reduce((c, itm) => {
    if (!EXCLUDED_FROM_TOTAL.includes(itm.variant.product.handle)) {
      return c + itm.variant.discount_percent_off_luxe;
    }
    return c;
  }, 0);
  discountLuxe =
    discountTieredOfferLuxe - discountTieredOffer + (discountPercentOffLuxe - discountPercentOff);
  discountFull = discountCompareAtPrice + discountLuxe + discountTieredOffer;

  // if (discountInfo.type === DISCOUNT_TYPES.AMOUNT_VALUE) {
  //   const dollarsOff = findDiscountInThresholds(discountInfo.discounts, fullAmount);
  //   finalAmount = fullAmount - dollarsOff;
  // }

  // let freeGift = null;
  // if (discountInfo?.freegift) {
  //   freeGift = discountedLineItems.find(
  //     (itm) =>
  //       (itm?.attributes || []).find(({ key, value }) => key === "freegift" && value === "true") &&
  //       itm.variant.product.handle === discountInfo.freegift.product.handle
  //   );
  //   if (freeGift) {
  //     finalAmount -= freeGift.variant.final_price;
  //   }
  // }

  const tierDiscountInfo = discountsInfo.find(
    ({ type }) => type === DISCOUNT_TYPES.QUANTITY_PERCENT
  );
  const eligibleItems = lineItems.reduce((acc, itm) => {
    if (!tierDiscountInfo) return acc;
    // filter items based on:
    // inclusion tags (include): items that don't have any of the inclusion tags are not eligible
    // exclusion tags (exclude): items that have at least one of the exclusion tags are not eligible
    if (tierDiscountInfo.matchType === "exclude") {
      return itm.variant.product.tags.some((tag) => tierDiscountInfo.matchTags.includes(tag))
        ? acc
        : acc + itm.quantity;
    }
    if (tierDiscountInfo.matchType === "include") {
      return itm.variant.product.tags.some((tag) => tierDiscountInfo.matchTags.includes(tag))
        ? acc + itm.quantity
        : acc;
    }
    return acc;
  }, 0);

  return {
    lines: discountedLineItems,
    nonBannedItemQuantity: eligibleItems,
    fullAmount,
    finalAmount,
    finalAmountLux,
    discountCompareAtPrice,
    discountLuxe,
    discountTieredOffer,
    discountFull,
    freegiftLineItemId: null,
  };
}
