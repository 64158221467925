import PropTypes from "prop-types";

import { useExclusionTags } from "../../../../hooks/useExclusionTags";
import { useAnnouncementBarPopup } from "../../../AnnouncementBar/announcementBar";
import { useAnnouncementBar } from "../../../../hooks/useAnnouncementBar";
import { useLocalizedSentenceDict } from "../../../../hooks/useSentenceDict";
import useDiscountsInfo from "../../../../hooks/useDiscountsInfo";

import DISCOUNT_TYPES from "../../../../constants/DiscountTypes";

import "../../product_details.scss";

function DiscountBanner({ handle, tags }) {
  const discountsInfo = useDiscountsInfo();
  const [, setPopupVisible] = useAnnouncementBarPopup();
  const { backgroundColor, textColor, hasPopup } = useAnnouncementBar();
  const exclusionTags = useExclusionTags();
  const isBannedProduct = (tags[handle] || []).some((r) => exclusionTags.includes(r));
  const dict = useLocalizedSentenceDict();
  if (isBannedProduct) return null;

  let _backgroundColor = backgroundColor;
  let _textColor = textColor;
  if (backgroundColor === "#000000") {
    _backgroundColor = "#F8F5F1";
    _textColor = "#000000";
  }
  return discountsInfo &&
    discountsInfo.length === 1 &&
    discountsInfo[0].type === DISCOUNT_TYPES.BOGO ? (
    <div
      className="discount_banner"
      style={{
        backgroundColor: _backgroundColor,
        color: _textColor,
      }}
    >
      <div className="discount_item discount_item_bogo">
        {dict.get("{0}% off your second piece", discountsInfo[0].value)}
      </div>
      <p style={{ color: _textColor }}>
        {dict.get("Code automatically applied at checkout. Exclusions apply.")}{" "}
        {hasPopup && (
          <a href="#" onClick={() => setPopupVisible(true)} style={{ color: _textColor }}>
            <span>{dict.get("Order deadlines")}</span>
          </a>
        )}
      </p>
    </div>
  ) : null;
}
DiscountBanner.propTypes = {
  handle: PropTypes.string.isRequired,
  tags: PropTypes.shape({}),
};

export default DiscountBanner;
