import PropTypes from "prop-types";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "@reach/router";

import { LoopReturnContext } from "./LoopReturnProvider";
import { formatPriceAmount } from "../hooks/usePrices";
import { getAllUrlParams, roundNumber } from "./helpers";
import { useAuth } from "./AuthProvider";
import { withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { GeoContext } from "./GeoProvider";
import { CartContext } from "./CartProvider";
import { Country } from "../constants/Country";

const defaultLuxeContext = {
  luxe: {
    discountLuxe: 0.05, // Extra discount on Tiered Offer
    discountLuxeProducts: 0.05, // Extra discount on Solid Gold item
    extraDiscountTags: ["Components:10K Solid Gold", "Components:10K Solid White Gold"],
    isLuxeEnabled: false,
    isLuxeActive: false,
    shouldAddLuxeInNextATC: true,
    subscriptionPlan: null,
    customerSubscription: null,
    subscriptionCredit: null,
    hasLuxeInCart: false,
    accountDetailsLoaded: false,
  },
  setLuxeActive: () => {},
  addLuxeItemToCart: () => {},
  removeLuxeItemToCart: () => {},
  applyLuxeDiscountToProductPrice: () => {},
  updateSubscriptionStatus: () => {},
};

export const LuxeContext = createContext(defaultLuxeContext);

function buildLuxeProductDataToAdd(plan) {
  return {
    quantity: 1,
    attributes: [],
    variant: {
      id: `gid://shopify/ProductVariant/${plan.exison_plan_settings.value.product_variant_id}`,
      sku: "",
      // price: variant.price,
      // compareAtPrice: variant.compareAtPrice,
      sellingPlanId: `gid://shopify/SellingPlan/${plan.exison_plan_settings.value.plan_id}`,
      product: {
        title: plan.exison_plan_settings.value.product_name,
        id: `gid://shopify/Product/${plan.exison_plan_settings.value.product_gid}`,
        handle: "al-luxe",
        productType: "subscribfy_subscription_product",
      },
    },
  };
}

export function useAddToCartWithLuxe() {
  const { addManyProductsToCart } = useContext(CartContext);
  const {
    luxe: { shouldAddLuxeInNextATC, subscriptionPlan },
  } = useContext(LuxeContext);

  return useCallback(
    async (props) => {
      if (shouldAddLuxeInNextATC && subscriptionPlan) {
        props.dataProductsToAdd.push(buildLuxeProductDataToAdd(subscriptionPlan));
      }
      return addManyProductsToCart(props);
    },
    [addManyProductsToCart, shouldAddLuxeInNextATC, subscriptionPlan]
  );
}

function LuxeProvider({ subscription, luxeEdit, children }) {
  const location = useLocation();
  const { accountDetails } = useAuth();
  const { gePriceDetails } = useContext(GeoContext);
  const { cart, addSingleProductToCart, removeSingleProductsFromCart, setIsSideCartOpen } =
    useContext(CartContext);
  const { isLoopReturnEnabled } = useContext(LoopReturnContext);

  const [subscriptionCredit, setSubscriptionCredit] = useState(0);
  const [luxe, setLuxe] = useState(defaultLuxeContext.luxe);
  const [canAutoAddLuxeInCart, setCanAutoAddLuxeInCart] = useState(true);

  const countryCode = gePriceDetails?.CountryCode;

  useEffect(() => {
    if (accountDetails && accountDetails.metafields && accountDetails.metafields.length > 0) {
      const subscriptionMetafield = accountDetails?.metafields.find(
        (m) => m.namespace === "exison" && m.key === "customer_subscription1"
      )?.value;
      const customerSubscription =
        (subscriptionMetafield && JSON.parse(subscriptionMetafield)) || null;

      setLuxe((prevLuxe) => ({
        ...prevLuxe,
        customerSubscription,
        accountDetailsLoaded: true,
      }));
    } else {
      // Reset Luxe (on logout)
      setLuxe(defaultLuxeContext.luxe);
    }
  }, [accountDetails]);

  useEffect(() => {
    try {
      if (accountDetails && accountDetails.metafields) {
        const storeCredits = accountDetails.metafields.find(
          (m) => m.namespace === "exison" && m.key === "exison_st"
        );
        if (storeCredits) {
          setSubscriptionCredit(storeCredits.value);
        }
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }, [accountDetails, countryCode]);

  // Store Metafield (for subscription)
  const subscriptionPlan = useMemo(
    () =>
      subscription.reduce((red, metafield) => {
        let data = {
          ...red,
        };
        if (metafield?.type === "json_string") {
          data = {
            ...red,
            [metafield.key]: {
              ...metafield,
              value: JSON.parse(metafield.value),
            },
          };
          delete data[metafield.key].key;
        }
        return data;
      }, {}),
    [subscription]
  );

  const addLuxeItemToCart = useCallback(
    async (plan, shouldOpenSideCart) => {
      if (plan) {
        setCanAutoAddLuxeInCart(true);
        const dataProductToAdd = buildLuxeProductDataToAdd(plan);
        return addSingleProductToCart({ dataProductToAdd, shouldOpenSideCart });
      }
    },
    [addSingleProductToCart]
  );

  const removeLuxeItemFromCart = useCallback(
    async (lineItem, shouldOpenSideCart) => {
      setCanAutoAddLuxeInCart(false);
      return removeSingleProductsFromCart({ lineToRemove: lineItem, shouldOpenSideCart });
    },
    [removeSingleProductsFromCart]
  );

  const setLuxeActive = useCallback(
    async (isLuxe, plan, cart, shouldOpenSideCart) => {
      const luxeLineItem = cart.lines.find(
        (l) =>
          l.variant.id ===
          `gid://shopify/ProductVariant/${plan.exison_plan_settings.value.product_variant_id}`
      );

      if (isLuxe) {
        if (typeof luxeLineItem === "undefined" && cart.lines.length > 0) {
          await addLuxeItemToCart(plan, shouldOpenSideCart);
        } else {
          setCanAutoAddLuxeInCart(true);
        }
      } else if (luxeLineItem) {
        await removeLuxeItemFromCart(luxeLineItem, shouldOpenSideCart);
      } else {
        setCanAutoAddLuxeInCart(false);
      }
    },
    [addLuxeItemToCart, removeLuxeItemFromCart]
  );

  /*
    Listen to location changes - for the auth search parameter
    i.e. http://localhost:8888/collections/bestsellers?luxe=true will open cart + activate luxe
    i.e. http://localhost:8888/collections/bestsellers?luxe=false will open cart + de-activate luxe
  */
  useEffect(() => {
    const queryParams = getAllUrlParams(location.href);
    if (queryParams.luxe) {
      setLuxeActive(queryParams.luxe === "true", subscriptionPlan, cart);
      setIsSideCartOpen(true);
    }
  }, [location, subscriptionPlan, cart, setLuxeActive, setIsSideCartOpen]);

  // status can be "ACTIVE", "PAUSED", "CANCELLED"
  const updateSubscriptionStatus = useCallback((status) => {
    setLuxe((prevLuxe) => ({
      ...prevLuxe,
      customerSubscription: {
        ...prevLuxe.customerSubscription,
        status,
      },
    }));
  }, []);

  const updateSubscriptionNextBillingDate = useCallback((date) => {
    setLuxe((prevLuxe) => ({
      ...prevLuxe,
      customerSubscription: {
        ...prevLuxe.customerSubscription,
        next_billing: date,
      },
    }));
  }, []);

  const applyLuxeDiscountToProductPrice = useCallback(
    (price) => {
      const finalPrice = parseFloat(price) - parseFloat(price) * luxe.discountLuxe;

      return formatPriceAmount(
        roundNumber(finalPrice),
        gePriceDetails?.CurrencyCode,
        gePriceDetails?.CountryCode
      );
    },
    [gePriceDetails, luxe.discountLuxe]
  );

  const hasLuxeInCart = useMemo(
    () =>
      !!cart.lines.find(
        (l) =>
          l.variant.id ===
          `gid://shopify/ProductVariant/${subscriptionPlan.exison_plan_settings.value.product_variant_id}`
      ),
    [cart.lines, subscriptionPlan.exison_plan_settings.value.product_variant_id]
  );

  const isLuxeActive = luxe.customerSubscription?.status === "ACTIVE";
  const isLuxeEnabled = countryCode === Country.US && !isLoopReturnEnabled;
  const shouldAddLuxeInNextATC = canAutoAddLuxeInCart && isLuxeEnabled && !cart.lines.length;

  // // Set cart attributes if different from the current state
  // const luxeCartAttribute = cart.attributes?.find((attr) => attr.key === "isLuxe");
  // useEffect(() => {
  //   const isLuxe = isLuxeActive || hasLuxeInCart;
  //   console.log(cart.attributes, "isLuxe:", isLuxe);
  //   if (luxeCartAttribute?.value !== isLuxe.toString()) {
  //     updateCartAttributes({
  //       customAttributes: [...(cart.attributes || []), { key: "isLuxe", value: isLuxe.toString() }],
  //     });
  //   }
  // }, [isLuxeActive, hasLuxeInCart, cart.attributes, updateCartAttributes]);

  const luxeData = useMemo(
    () => ({
      ...defaultLuxeContext,
      luxe: {
        ...defaultLuxeContext.luxe,
        ...luxe,
        isLuxeEnabled,
        isLuxeActive,
        shouldAddLuxeInNextATC,
        subscriptionPlan,
        hasLuxeInCart,
        subscriptionCredit,
        luxeEdit,
      },
      setLuxeActive,
      addLuxeItemToCart,
      removeLuxeItemFromCart,
      applyLuxeDiscountToProductPrice,
      updateSubscriptionStatus,
      updateSubscriptionNextBillingDate,
    }),
    [
      luxe,
      setLuxeActive,
      updateSubscriptionStatus,
      addLuxeItemToCart,
      removeLuxeItemFromCart,
      isLuxeActive,
      hasLuxeInCart,
      subscriptionPlan,
      updateSubscriptionNextBillingDate,
      applyLuxeDiscountToProductPrice,
      subscriptionCredit,
      isLuxeEnabled,
      shouldAddLuxeInNextATC,
      luxeEdit,
    ]
  );

  return <LuxeContext.Provider value={luxeData}>{children}</LuxeContext.Provider>;
}
export default withALErrorBoundary({
  name: "LuxeProvider",
  priority: "P1",
})(LuxeProvider);

LuxeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  subscription: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      namespace: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  luxeEdit: PropTypes.shape({
    collection: PropTypes.shape({
      handle: PropTypes.string,
    }),
    title: PropTypes.string,
    buttonText: PropTypes.string,
    image: PropTypes.shape({
      desktopImage: PropTypes.shape({
        gatsbyImageData: PropTypes.object,
      }),
      mobileImage: PropTypes.shape({
        gatsbyImageData: PropTypes.object,
      }),
    }),
  }),
};
