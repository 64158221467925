import { useContext, useMemo } from "react";
import { navigate } from "gatsby";
import { format, parseISO } from "date-fns";

import { GeoContext } from "../context/GeoProvider";
import { useAddToCartWithLuxe } from "../context/LuxeProvider";
import useDiscountsInfo from "./useDiscountsInfo";
import { applyIgPrices } from "../helpers/intelligems";
import { useALError } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { Country } from "../constants/Country";
import { InventoryPolicies } from "../constants/InventoryPolicies";

const useCart = (product, productPrices) => {
  const fullProduct = useMemo(
    () => ({ ...product.node, ...product.contentful.node }),
    [product.node, product.contentful]
  );
  const { sendReport } = useALError();
  const discountsInfo = useDiscountsInfo();
  const { gePriceDetails } = useContext(GeoContext);
  const addToCartWithLuxe = useAddToCartWithLuxe();

  const igPrices = applyIgPrices(fullProduct.id, fullProduct.variants[0], productPrices);

  const isPreOrder =
    fullProduct.isPreOrder &&
    fullProduct.variants[0]?.inventoryPolicy === InventoryPolicies.CONTINUE;

  const shippingDate = isPreOrder
    ? (() => {
        try {
          const parsedDate = parseISO(fullProduct.preOrderShippingDate);
          return format(parsedDate, "MMM d, yyyy");
        } catch (error) {
          console.error(error);
          return null;
        }
      })()
    : null;

  const quickAddToCart = async () => {
    const { title, handle, images } = fullProduct;
    const price = parseFloat(igPrices.finalPrice);
    let compareAtPrice = parseFloat(igPrices.fullPrice);
    if (igPrices.finalPrice === igPrices.fullPrice) {
      compareAtPrice = 0;
    }

    const { sku, id: variantId } = fullProduct.variants[0];
    const isFinalSale = fullProduct?.tags?.includes("Final Sale");

    try {
      const dataProductToAdd = {
        quantity: 1,
        attributes: [],
        variant: {
          id: variantId,
          sku,
          compareAtPrice,
          price,
          product: {
            title,
            id: fullProduct.id,
            handle,
            productType: fullProduct.productType,
            images,
          },
        },
      };

      dataProductToAdd.attributesProperties = {
        isPreOrder,
        preOrderShipDate: shippingDate || null,
        isUS: gePriceDetails?.CountryCode === Country.US,
        currencyCode: productPrices.currencyCode,
        prices: productPrices,
        isFinalSale,
        igPrices,
        discountsInfo,
      };

      await addToCartWithLuxe({ dataProductsToAdd: [dataProductToAdd] });
    } catch (error) {
      sendReport(error, { name: "useCart", priority: "P1" });
      if (fullProduct?.handle) {
        navigate(`/products/${fullProduct.handle}`);
      }
    }
  };

  return { quickAddToCart };
};

export default useCart;
