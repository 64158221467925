import { useEffect, useState } from "react";
import { PropTypes } from "prop-types";

import useDeviceDetect from "../../hooks/useDeviceDetect";
import { useAnnouncementBar } from "../../hooks/useAnnouncementBar";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import useDiscountsInfo from "../../hooks/useDiscountsInfo";

import IconClose from "../icons/svgs/close.svg";
import IconAdd from "../icons/svgs/add.svg";
import IconMinus from "../icons/svgs/minus.svg";
import { Popup } from "../Popup";
import { ALLink as Link } from "../ALComponents";
import ABPopupFreeGift from "./ABPopupFreeGift";
import DISCOUNT_TYPES from "../../constants/DiscountTypes";

import * as styles from "./AnnouncementBar.module.scss";

function ABPopup({ open, onClose, countryCode }) {
  const { backgroundColor, textColor, popup } = useAnnouncementBar();
  const discountsInfo = useDiscountsInfo();
  const { isMobile } = useDeviceDetect();
  const dict = useLocalizedSentenceDict();

  const [isDetailOpen, setDetailOpen] = useState(false);
  const [deadlineCountry, updateDeadlineCountry] = useState(null);

  useEffect(() => {
    if (countryCode && popup?.deadlines?.internal?.content) {
      let jsonDeadline = JSON.parse(popup.deadlines.internal.content)[countryCode];
      if (!jsonDeadline) {
        jsonDeadline = JSON.parse(popup.deadlines.internal.content).DEFAULT;
      }
      if (jsonDeadline) {
        updateDeadlineCountry(jsonDeadline);
      }
    }
  }, [countryCode]);

  const shippingInformation = useResponsiveWithHydrationFix(
    <button
      type="button"
      className={`${styles.popup_shipping_info_mobile}`}
      onClick={() => setDetailOpen(!isDetailOpen)}
      data-testid="shipping-info-button"
    >
      <p className={`al_p ${styles.popup_shipping_info_mobile_title}`}>
        <span>*{dict.get("Important Shipping Information")}</span>
        {isDetailOpen ? (
          <IconMinus width="30" stroke={popup?.textColor || textColor} />
        ) : (
          <IconAdd width="30" fill={popup?.textColor || textColor} />
        )}
      </p>
      {isDetailOpen ? (
        <div className={`${styles.popup_shipping_info_mobile_content}`}>
          <p className="al_p">
            {dict.get(
              "Orders may take longer than expected to arrive based on inclement weather, holidays, and/or carrier delays, and this is out of our control."
            )}
          </p>
        </div>
      ) : null}
    </button>,
    <div className={`${styles.popup_shipping_info_desktop}`}>
      <p className="al_p">
        *
        {dict.get(
          "Orders may take longer than expected to arrive based on inclement weather, holidays, and/or carrier delays, and this is out of our control."
        )}
      </p>
    </div>
  );

  const shippingDeadline = useResponsiveWithHydrationFix(
    <>
      {deadlineCountry && deadlineCountry.standard ? (
        <>
          <p className={`al_p al_p--bold ${styles.popup_title_shipping_type}`}>
            {dict.get("Standard")}*
          </p>
          <p className={`al_p ${styles.popup_title_shipping_date}`}>{deadlineCountry.standard}</p>
        </>
      ) : null}

      {deadlineCountry && deadlineCountry.express ? (
        <>
          <p className={`al_p al_p--bold ${styles.popup_title_shipping_type}`}>
            {dict.get("Express")}*
          </p>
          <p className={`al_p ${styles.popup_title_shipping_date}`}>{deadlineCountry.express}</p>
        </>
      ) : null}
    </>,
    <div className={`${styles.popup_title_deadline_content}`}>
      {deadlineCountry && deadlineCountry.standard ? (
        <div className={`${styles.popup_title_deadline_c}`}>
          <p className={`al_p al_p--bold ${styles.popup_title_shipping_type}`}>
            {dict.get("Standard")}*
          </p>
          <p className={`al_p ${styles.popup_title_shipping_date}`}>{deadlineCountry.standard}</p>
        </div>
      ) : null}

      {deadlineCountry && deadlineCountry.express ? (
        <div className={`${styles.popup_title_deadline_c}`}>
          <p className={`al_p al_p--bold ${styles.popup_title_shipping_type}`}>
            {dict.get("Express")}*
          </p>
          <p className={`al_p ${styles.popup_title_shipping_date}`}>{deadlineCountry.express}</p>
        </div>
      ) : null}
    </div>
  );

  if (!discountsInfo.length) {
    return null;
  }

  const quantityPercentDiscount = discountsInfo.find(
    ({ type }) => type === DISCOUNT_TYPES.QUANTITY_PERCENT
  );

  return (
    <Popup className="popup_ab" open={open} onClose={onClose}>
      <div style={{ backgroundColor, color: popup?.textColor || textColor }} data-testid="ab-popup">
        <button
          onClick={onClose}
          className={styles.close_button}
          type="button"
          data-testid="close-button"
        >
          <IconClose width="30" fill={popup?.textColor || textColor} />
        </button>
        <div
          className={`${styles.popup_container} ${
            popup?.backgroundImage?.title !== "empty" ? styles.popup_container_image : ""
          }`}
        >
          {popup?.backgroundImage?.title !== "empty" && (
            <div className={styles.popup_container_custom_image}>
              <img src={popup?.backgroundImage?.url} alt={popup?.backgroundImage?.title} />
              <Link
                className="al_p--bold"
                to="/pages/promo-sale"
                style={{ color: popup?.textColor || textColor }}
                onClick={onClose}
              >
                <u>{dict.get("Exclusions Apply")}</u>
              </Link>
            </div>
          )}
          {popup?.backgroundImage?.title === "empty" &&
            popup?.title === "custom_popup_free_gift" && (
              <ABPopupFreeGift
                discount={discountsInfo.find(({ type }) => type === DISCOUNT_TYPES.FREE_GIFT)}
                textColor={popup?.textColor || textColor}
                data={JSON.parse(popup?.deadlines?.internal?.content)}
              />
            )}
          {popup?.backgroundImage?.title === "empty" && popup?.title !== "custom_popup_free_gift" && (
            <>
              <h2 className={`al_h4 ${styles.popup_container_subtitle}`}>
                {popup && popup?.title ? `${dict.get(popup.title)}` : ""}
              </h2>

              {quantityPercentDiscount &&
                quantityPercentDiscount.discounts?.map((discount, index) => {
                  const isLastItem = index === quantityPercentDiscount.discounts?.length - 1;
                  return (
                    <div className="al_h1" key={`promo_${index}`}>
                      {isMobile
                        ? `${discount[1]}% off ${discount[0]}${isLastItem ? "+" : ""} item${
                            discount[0] > 1 ? "s" : ""
                          }${isLastItem ? "" : ","}`
                        : dict.get(
                            "Buy {0}, get {1}% off",
                            `${discount[0]}${isLastItem ? "+" : ""}`,
                            discount[1]
                          )}
                    </div>
                  );
                })}

              {discountsInfo.some(({ type }) => type === DISCOUNT_TYPES.BOGO) && (
                <div className="al_h1">{dict.get(popup.subtitle)}</div>
              )}

              {deadlineCountry && (
                <>
                  <p className={`al_p ${styles.popup_title_deadline}`}>
                    {dict.get("Order deadlines")}:
                  </p>
                  {shippingDeadline}
                </>
              )}

              {shippingInformation}

              <p className="al_p">
                {dict.get(
                  "Code automatically applied at checkout. Limited-time online sale. Conditions apply."
                )}
                <Link
                  className="al_p--bold"
                  to="/pages/promo-sale"
                  style={{ color: popup?.textColor || textColor }}
                  onClick={onClose}
                >
                  <u>{dict.get("Read more")}</u>
                </Link>
              </p>
            </>
          )}
        </div>
      </div>
    </Popup>
  );
}

ABPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  countryCode: PropTypes.string,
};

export default ABPopup;
